import HTTP from "../http-common";

// Получаем инфу о брони для оплаты:
// номер счета - invoice_id,
// срок жизни брони в часах с момента создания  - precost_date_expire,
// инфу объекта, о госте, холдирование и т.д.
// передаем параметры: orderId

export default (formData) =>
  HTTP.post("json/orders/getGuestOrderData", formData, {
    headers: {
      "api-version": "1.9",
    },
    cache: false,
  });
